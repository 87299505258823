import { NgClass } from '@angular/common';
import { Component, inject, input, output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@data/analytics/analytics.service';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';

@Component({
  selector: 'ui-slidein',
  standalone: true,
  imports: [NgClass, CustomIconsModule],
  templateUrl: './slidein.component.html',
})
export class SlideinComponent {
  slideInKey = input<string>();
  title = input<string>();
  subtitle = input<string>();
  onClose = output<void>();

  public isOpen = signal(false);

  analyticsService = inject(AnalyticsService);
  router = inject(Router);

  public open(): void {
    this.isOpen.set(true);

    this.analyticsService.pushEvent(
      this.analyticsService.createSlideInEvent({
        page_path: this.router.url,
        slide_in_key: this.slideInKey(),
      }),
    );
  }

  protected close(): void {
    this.isOpen.set(false);
    this.onClose.emit();
  }

  protected stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
