<div
  class="fixed inset-0 z-50 h-full w-full overflow-y-auto bg-black/20"
  [ngClass]="{ invisible: !isOpen() }"
  (click)="close()"
>
  <div
    (click)="stopPropagation($event)"
    class="duration-400 absolute right-0 top-0 z-[100] min-h-full w-[320px] bg-white px-4 py-12 transition-transform ease-out sm:w-[480px]"
    [ngClass]="isOpen() ? 'translate-x-0' : 'translate-x-full'"
  >
    <div class="mb-8 flex justify-between">
      <div class="flex flex-col gap-4">
        <h3>{{ title() }}</h3>
        <p class="text-gray-600">{{ subtitle() }}</p>
      </div>

      <fa-icon
        (click)="close()"
        class="fa-xl flex cursor-pointer flex-col justify-start p-2 text-gray-400"
        [icon]="['far', 'xmark']"
      ></fa-icon>
    </div>
    <ng-content></ng-content>
  </div>
</div>
